import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Button, message } from 'antd';  // Import message from Ant Design
import { useDispatch, useSelector } from 'react-redux';
import ActionsRender from './ActionsRender';
import DB from '../DAL/DB';
import save from './save';

const ActionsReq = ({ ratingsData, iconsData, setRatingsData, setIconsData }) => {
  const { actionList: actionListDB } = useSelector((state) => state.actionsState);
  const { saveEnterprisePDFOptionChecked } = useSelector((state) => state.form);
  const [actionList, setActionList] = useState(actionListDB || []); 
  const dispatch = useDispatch();
  const form = useSelector((state) => state.form);
  const formAction = new DB.FormAction(form.id);
  const [isSaving, setIsSaving] = useState(false); // To track save state

  useEffect(() => {
    dispatch({ type: 'saveAction', value: actionList });
  }, [actionList, dispatch]);

  const actionObj = useMemo(() => ({
    id: '',
    strategy: '',
    criticality: '',
    cost: '',
    difficulty: '',
    icon: '',
    actionDescription: '',
    referenceInformation: '',
    tags: [],
  }), []);

  const handleSaveReduxState = useCallback(async (updatedActionList) => {
    updatedActionList.filter(x => x.isDelete).forEach((rec) => {
      rec.actionIndex = 0;
    });
    updatedActionList.filter(x => !x.isDelete).forEach((rec, index) => {
      rec.actionIndex = index + 1;
    });
    setTimeout(() => {
      dispatch({ type: 'saveAction', value: updatedActionList });
      setIsSaving(true)
    }, 10);
  }, [dispatch]);

  const handleDeleteAction = (action) => {
    const editActions = [...actionList]
    const editedRec = editActions.find((x) => x.id === action.id)
    editedRec.isDelete = 'confirmation'
    setActionList(editActions)
    handleSaveReduxState(editActions)
  }

  const handleDeleteActionCancel = () => {
    const editActions = [...actionList]
    const editedRec = editActions.find((x) => x.isDelete === 'confirmation')
    editedRec.isDelete = false
    setActionList(editActions)
    handleSaveReduxState(editActions)
  }

  const handleDeleteActionConfirmation = () => {
    const editActions = [...actionList]
    const editedRec = editActions.find((x) => x.isDelete === 'confirmation')
    editedRec.isDelete = true
    setActionList(editActions)
    handleSaveReduxState(editActions)
  }

  const handleInputChange = useCallback((id, field, value) => {
    setActionList(prevActionList => {
      const updatedActionList = prevActionList.map((action) => {
        if (action.id === id) {
          return { ...action, [field]: value };  // Update only the specific field
        }
        return action;
      });
      handleSaveReduxState(updatedActionList);  // Ensure state is saved after update
      return updatedActionList;
    });
  }, [handleSaveReduxState]);

  const handleAddActions = useCallback(() => {
    const updatedActionList = [...actionList];
    const newAction = { ...actionObj, id: formAction.makeId() };
    updatedActionList.push(newAction);
    setActionList(updatedActionList);
    handleSaveReduxState(updatedActionList);
  }, [actionList, actionObj, formAction, handleSaveReduxState]);

  const handleSaveActions = useCallback(() => {
    setIsSaving(true); // Set saving state
    save()
      .then(() => {
        message.success({ content: 'Data saved successfully!', key: 'saving', duration: 2 });  // Show success message
      })
      .catch(() => {
        message.error({ content: 'Error saving data!', key: 'saving', duration: 2 }); // Show error message if save fails
      })
      .finally(() => {
        setIsSaving(false); // Reset saving state
      });
  }, []);

  const renderActions = useMemo(() => (
    <ActionsRender
      actionList={actionList}
      handleInputChange={handleInputChange} // Pass the handleInputChange function
      handleDeleteAction={handleDeleteAction}
      handleDeleteActionCancel={handleDeleteActionCancel}
      handleDeleteActionConfirmation={handleDeleteActionConfirmation}
      ratingsData={ratingsData}
      iconsData={iconsData}
      setRatingsData={setRatingsData}
      setIconsData={setIconsData}
    />
  ), [
    actionList,
    handleInputChange,
    handleDeleteAction,
    handleDeleteActionCancel,
    handleDeleteActionConfirmation,
    ratingsData,
    iconsData,
    setRatingsData,
    setIconsData,
  ]);

  const renderActionbuttons = useMemo(() => (
    <React.Fragment>
      <Button className="formAction" title="add-tag" icon="plus" onClick={handleAddActions} />
      <Button
        ghost={false}
        className="thick saveActionbtn"
        type="primary"
        size="large"
        loading={isSaving}  // Show loading spinner while saving
        onClick={handleSaveActions} // Updated save button to show message
      >
        Save
      </Button>
    </React.Fragment>
  ), [handleAddActions, handleSaveActions, isSaving]);
  const handleGeneratePDFSelection = (e) => {
    dispatch({type: 'saveEnterprisePDFOption', value: e.target.checked})
    setIsSaving(true)
  }
  return (
    <div>
      {renderActionbuttons}
      <div>
      <div className="generatePDF">
          <input
            type="checkbox"
            key={`chk-${saveEnterprisePDFOptionChecked}`}
            defaultChecked={saveEnterprisePDFOptionChecked}
            onChange={handleGeneratePDFSelection}
          />
          <label className="generatePDFLabel">Generate Action Report for all Projects using this Form</label>
        </div>
        <h3> Available Actions</h3>
      </div>
      <React.Fragment key={`action-${actionList.length}`}>{renderActions}</React.Fragment>
    </div>
  );
};

export { ActionsReq };
