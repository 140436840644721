import {Filter, Snapshot, DBResponse, QueryOptions, FITQMResource, FirebaseResource, JSONObject} from './Types'
import {firebase} from '../utilities'

interface FilterComparators {
  [index: string]: firebase.firestore.WhereFilterOp
}

export default class BaseModel {
  baseQuery: firebase.firestore.CollectionReference
  comparisonLookup: FilterComparators = {}
  type: string

  constructor(baseQuery: firebase.firestore.CollectionReference, type: string = '') {
    this.baseQuery = baseQuery
    this.type = type
  }

  makeFilter([where, comparison, value]: Filter): Filter {
    const comp: firebase.firestore.WhereFilterOp = comparison
    return [where, comp, value]
  }

  normalize(snapshotArray: firebase.firestore.DocumentSnapshot[]): (firebase.firestore.DocumentData | undefined)[] {
    return snapshotArray.map((d) => ({...d.data(), id: d.id}))
  }

  denormalize(update: JSONObject): JSONObject {
    console.log('implement the denormalize method')
    return update
  }

  convertQueryResult(
    snapshot: Snapshot,
    callback?: (resources: DBResponse<(JSONObject | undefined)[]>) => void
  ): DBResponse<(JSONObject | undefined)[]> | null {
    let data: (JSONObject | undefined)[] = []
    let dataBundle: DBResponse<(JSONObject | undefined)[]> = {data, ok: false}

    if (snapshot instanceof firebase.firestore.QuerySnapshot && !snapshot.empty) {
      data = this.normalize(snapshot.docs)
      dataBundle = {data, ok: true}
    } else if (snapshot instanceof firebase.firestore.DocumentSnapshot && snapshot.exists) {
      data = this.normalize([snapshot])
      dataBundle = {data, ok: true}
    }

    if (callback) {
      callback(dataBundle)
      return null
    }

    return dataBundle
  }

  makeQuery(
    id: string | undefined,
    options: QueryOptions | undefined
  ): firebase.firestore.Query | firebase.firestore.DocumentReference {
    let query: firebase.firestore.Query | firebase.firestore.DocumentReference

    if (id) query = this.baseQuery.doc(id)
    else if (!options) query = this.baseQuery
    else {
      query = this.baseQuery
      if (options.sort) {
        let direction: firebase.firestore.OrderByDirection | undefined = undefined
        switch (options.direction) {
          case 'asc':
            direction = 'asc'
            break
          case 'desc':
            direction = 'desc'
            break
          default:
            break
        }
        query = query.orderBy(options.sort, direction)
      }
      if (options.filters) query = options.filters.reduce((a, filter) => a.where(...this.makeFilter(filter)), query)
      if (options.limit) query = query.limit(options.limit)
      if (options.startAt) query = query.startAt(options.startAt)
      if (options.endAt) query = query.endAt(options.endAt)
    }

    return query
  }

  async get(
    id: string | undefined,
    options: QueryOptions | undefined
  ): Promise<DBResponse<(JSONObject | undefined)[]> | null> {
    const query = this.makeQuery(id, options)
    const snapshot: Snapshot = await query.get()
    return this.convertQueryResult(snapshot)
  }

  async update(id: string, options: QueryOptions | null, update: FITQMResource): Promise<boolean> {
    let data: FirebaseResource = this.denormalize(update)
    //console.info('Firebase Update:', this.baseQuery.path, data)
    try {
      await this.baseQuery.doc(id).set(data, {merge: (options || {}).noMerge ? false : true})
      return true
    } catch (e) {
      console.log(`failed to update resource. error: `, this.baseQuery.path, e)
      return false
    }
  }

  async updatebatch(id: string, options: QueryOptions | null, update: FITQMResource): Promise<boolean> {
    const batch = firebase.firestore().batch();
    let data: FirebaseResource = this.denormalize(update); // Denormalize the data
    try {
      // Get a reference to the document you're updating
      const docRef = this.baseQuery.doc(id);
      // Add the update operation to the batch
      batch.set(docRef, data, {merge: (options || {}).noMerge ? false : true});
      // Commit the batch operation
      await batch.commit();
      return true;
    } catch (e) {
      console.log(`Failed to update resource. Error:`, this.baseQuery.path, e);
      return false;
    }
  }

  // the callback function is for working with database objects after they have IDs, but before they are saved to the database
  //  for example, sites need a thumbnail of a map on them to show users the approximate site location;
  //  the images are saved to a location in the storage bucket based on the site ID
  //  see the Sites class for a working callback example
  async create(
    options: QueryOptions = {},
    obj: FITQMResource,
    callback?: (obj: JSONObject) => Promise<void>
  ): Promise<string | void> {
    let data: FirebaseResource = this.denormalize(obj)
    const id: string = this.makeId()
    try {
      if (callback) await callback({id, ...data})
      await this.baseQuery.doc(id).set(data)
      return id
    } catch (e) {
      console.log(`failed to create resource ${JSON.stringify(data)}. error: ${e.message}`)
      return
    }
  }

  async delete(id: string): Promise<boolean> {
    try {
      await this.baseQuery.doc(id).delete()
      return true
    } catch (e) {
      console.log(`failed to delete resource ${id}`)
      return false
    }
  }

  makeId() {
    return this.baseQuery.doc().id
  }

  subscribe(
    id: string = '',
    options: QueryOptions,
    callback: (results: DBResponse<JSONObject | undefined>) => void,
    errorCallback: (error: Error) => void
  ): () => void {
    const query = this.makeQuery(id, options)
    console.info('Subscribe - ', this.baseQuery.path, id, options)
    // DocumentReference and Query use different signatures for their onSnapshot calls
    //  so you have to disambiguate which one you're calling
    if (query instanceof firebase.firestore.DocumentReference) {
      const docQuery: firebase.firestore.DocumentReference = query
      const unsubscribe = docQuery.onSnapshot((snapshot) => this.convertQueryResult(snapshot, callback), errorCallback)
      return unsubscribe
    } else {
      const collectionQuery: firebase.firestore.Query = query
      const unsubscribe = collectionQuery.onSnapshot((snapshot) => this.convertQueryResult(snapshot, callback), errorCallback)
      return unsubscribe
    }
  }

}
