import React, { useEffect } from 'react';
import { Button, message } from 'antd';
import { Sticky } from 'react-sticky';
import { history } from '../history';
import { saveIntake } from './utilities';
import { connect } from 'react-redux';

export function TabBar({ project, highlightErrors, props, Default }) {
  // Function to preserve and restore scroll position
  const preserveScrollPosition = (callback) => {
    const scrollPosition = window.pageYOffset; // Capture the current scroll position
    callback(); // Perform the save or other operations
    window.scrollTo(0, scrollPosition); // Restore scroll position after save
  };

  async function saver(route = null) {
    try {
      // Show non-blocking loading message
      message.loading({ content: 'Auto-saving data...', key: 'saving', duration: 0 });

      // Preserve scroll position during the save operation
      preserveScrollPosition(async () => {
        await saveIntake(); // Trigger the save operation
      });

      message.success({ content: 'Data saved successfully!', key: 'saving', duration: 2 });

      // If route is provided (manual "Save and Exit"), navigate after saving
      if (route) {
        history.push(route);
      }
    } catch (e) {
      highlightErrors(e);
      message.error({ content: 'Error saving data.', key: 'saving', duration: 2 });
      console.log('Error during auto-save', e);
    }
  }

  // Auto-save every 90 seconds without interrupting the user experience
  useEffect(() => {
    const interval = setInterval(() => {
      saver(); // Auto-save the data
    }, 1800000); // 90 seconds interval
    
    return () => clearInterval(interval); // Clear interval on component unmount
  }, []); // Only run on mount and unmount

  return (
    <Sticky>
      {({ style }) => (
        <div className="flex-row background-white justify-space-between" style={{ ...style, zIndex: 10 }}>
          <Default {...props} style={{ width: '100%' }} />
          <div className="flex-row" style={{ display: 'none' }}>
            <Button style={{ margin: `1rem 1rem 1rem 0` }} type="default" onClick={() => saver()}>
              Save
            </Button>
            <Button
              style={{ margin: `1rem 0 1rem 0` }}
              type="primary"
              onClick={() => saver(`/projects/${project.id}/manage`)}
            >
              Save and Exit
            </Button>
          </div>
        </div>
      )}
    </Sticky>
  );
}

export default connect()(TabBar);
